import React, { useEffect } from "react";
import "../Contact/contact.css";
import Reservation from "../../components/Reservation/Reservation";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const ContactSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <>
    <Helmet>
        <title>Contact Us - ToyBoy</title>
        <meta name="description" content="Get in touch with ToyBoy. Find our contact details, addresses, and opening hours here." />
        <meta name="keywords" content="contact, ToyBoy, address, phone numbers, email, follow us, opening hours" />
      </Helmet>
      <div className="contactimage">
        <div className="contactoverlay">
          <div className="text-center findus text-white">
            <p>Find Us</p>
            <h1 className="heading">Contact</h1>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-md-4">
            <ul class="list-group">
              <li class="list-group-item mb-5">
                Gardens Galleria Mall, Ground Floor, Sector 38, Noida, Uttar
                Pradesh 201301
              </li>
              <li class="list-group-item mb-5">
                Uptown Square, Advant Navis Business Park Tower-D, Advant Navis
                Business Park, Unit 106, 1st floor, Expressway, Sec 142, Noida,
                Uttar Pradesh 201305
              </li>
              <li class="list-group-item mb-5">
                39, ISBT Main Road, Habib Ganj, Bhopal, Madhya Pradesh 462023
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul class="list-group">
              <li class="list-group-item mb-2">
                <Link className="text-black" to="tel:+919810856466">
                  +91 98108 56466
                </Link>
              </li>
              <li class="list-group-item mb-2">
                <Link className="text-black" to="tel:+919319453926">
                  +91 93194 53926
                </Link>
              </li>
              <li class="list-group-item mb-2">
                <Link className="text-black" to="tel:+919953152666">
                  +91 99531 52666
                </Link>
              </li>
            </ul>
            <ul class="list-group">
              <li class="list-group-item mb-2">
                <Link className="text-black" to="tel:+919810687826">
                  +91 9810687826
                </Link>
              </li>
              <li class="list-group-item mb-2">
                <Link className="text-black" to="tel:+919319453926">
                  +91 9319453926
                </Link>
              </li>
              <li class="list-group-item mb-2">
                <Link className="text-black" to="tel:+919810669276">
                  +91 9810669276
                </Link>
              </li>
            </ul>
            <ul class="list-group">
              <li class="list-group-item mb-2">
                <Link className="text-black" to="tel:+919978997806">
                  +91 9978997806
                </Link>
              </li>
              <li class="list-group-item mb-2">
                <Link className="text-black" to="tel:+9107554148410">
                  +91 07554148410
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul class="list-group">
              <li class="list-group-item mb-5">
                <Link className="text-black" to="mailto:advant@ToyBoyindia.in">
                  advant@ToyBoyindia.in
                </Link>{" "}
                /
                <Link className="text-black" to="mailto:vp@ToyBoyindia.in">
                  vp@ToyBoyindia.in
                </Link>{" "}
                /
                <Link className="text-black" to="mailto:bd@ToyBoyindia.in">
                  bd@ToyBoyindia.in
                </Link>
              </li>
            </ul>
            <ul class="list-group">
              <li class="list-group-item mb-5">
                <Link className="text-black" to="mailto:advant@ToyBoyindia.in">
                  advant@ToyBoyindia.in
                </Link>{" "}
                /
                <Link className="text-black" to="mailto:vp@ToyBoyindia.in">
                  vp@ToyBoyindia.in
                </Link>{" "}
                /
                <Link className="text-black" to="mailto:bd@ToyBoyindia.in">
                  bd@ToyBoyindia.in
                </Link>
              </li>
            </ul>
            <ul class="list-group">
              <li class="list-group-item mb-5">
                <Link className="text-black" to="mailto:vp@ToyBoyindia.in">
                  vp@ToyBoyindia.in
                </Link>{" "}
                /
                <Link className="text-black" to="mailto:bd@ToyBoyindia.in">
                  bd@ToyBoyindia.in
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <hr className="text-white mt-5" />
        <div className="row py-5 text-white mt-5">
          <div className="col-md-6">
            <h1>Follow Us</h1>
            <div className="folloLinks">
              <div className="facebook">
                <Link className="text-white" to="#">
                <i class="bi bi-facebook"></i>
                </Link>
              </div>
              <div className="insta">
              <Link className="text-white" to="#">
                <i class="bi bi-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h1>Opening Hours</h1>

            <h3>Monday-Sunday : 12PM-12AM</h3>
          </div>
        </div>
      </div>
      <Reservation />
    </>
  );
};

export default ContactSection;
