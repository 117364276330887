import React, { useEffect } from "react";
import "../FoodDrink/food.css";
import Reservation from "../../components/Reservation/Reservation";
import Slider from "react-slick";

// food menu
import foodmenu1 from "../../images/food1.jpeg";
import foodmenu2 from "../../images/food2.jpeg";
import foodmenu3 from "../../images/food3.webp";
import foodmenu4 from "../../images/food5.jpeg";

import foodmenu5 from "../../images/banglore/1.png";
import foodmenu6 from "../../images/banglore/2.png";
import foodmenu7 from "../../images/banglore/3.png";
import foodmenu8 from "../../images/banglore/4.png";
// food menu end
// drink menu
import drinkmenu1 from "../../images/banglore/drink1.png";
import drinkmenu2 from "../../images/banglore/drink2.png";
import drinkmenu3 from "../../images/banglore/drink3.png";
import drinkmenu4 from "../../images/banglore/drink4.png";

import drinkmenu24 from "../../images/drink1.webp";
import drinkmenu25 from "../../images/drink2.jpg";
import drinkmenu26 from "../../images/drink3.jpg";
import drinkmenu27 from "../../images/drink4.jpeg";
import { Helmet } from "react-helmet";
// drink menu end


const FoodDrink = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
    <Helmet>
        <title>Food & Drinks - Toy Boy</title>
        <meta name="description" content="Enjoy a diverse range of food and drinks at Toy Boy. From exquisite meals to refreshing drinks, there's something for every taste!" />
        <meta name="keywords" content="food, drinks, menu, Toy Boy, nightlife, dining, beverages" />
      </Helmet>
    <div className="food-drink-section py-5">
      <div className="container">
        <div className="foodBox">
          <h1 className="food_drink_heading text-white">𝕱𝖔𝖔𝖉 & 𝕯𝖗𝖎𝖓𝖐𝖘</h1>
          <p className="description text-white">
            Enjoy some food treatment with few of our best cooking styles that
            are passages to a taste bud paradise
          </p>
        </div>
      </div>

      <div className="container">
        <div className="row mb-5" style={{ alignItems: "center" }}>
          <div className="col-md-6">
            <div className="row mb-5">
              <h1 className="text-white">Food Menu</h1>
              <div className="col-6">
                <img
                  src={foodmenu1}
                  alt="food menu 1"
                  className="img-fluid rounded"
                />
              </div>
              <div className="col-6">
                <img
                  src={foodmenu2}
                  alt="food menu 2"
                  className="img-fluid rounded"
                />
              </div>
              <div className="col-6 mt-4">
                <img
                  src={foodmenu3}
                  alt="food menu 3"
                  className="img-fluid rounded"
                />
              </div>
              <div className="col-6 mt-4">
                <img
                  src={foodmenu4}
                  alt="food menu 4"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <Slider {...settings}>
              <div>
                <img
                  src={foodmenu5}
                  className="img-fluid w-100 h-100"
                  alt="Food Menu 5"
                />
              </div>
              <div>
                <img
                  src={foodmenu6}
                  className="img-fluid w-100 h-100"
                  alt="Food Menu 6"
                />
              </div>
              <div>
                <img
                  src={foodmenu7}
                  className="img-fluid w-100 h-100"
                  alt="Food Menu 7"
                />
              </div>
              <div>
                <img
                  src={foodmenu8}
                  className="img-fluid w-100 h-100"
                  alt="Food Menu 8"
                />
              </div>
            </Slider>
          </div>
        </div>
        <div className="row mt-5" style={{ alignItems: "center" }}>
          <div className="col-md-6">
            <Slider {...settings}>
              <div>
                <img
                  src={drinkmenu1}
                  className="img-fluid w-100"
                  alt="Drink Menu 1"
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <div>
                <img
                  src={drinkmenu2}
                  className="img-fluid w-100"
                  alt="Drink Menu 2"
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <div>
                <img
                  src={drinkmenu3}
                  className="img-fluid w-100"
                  alt="Drink Menu 3"
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <div>
                <img
                  src={drinkmenu4}
                  className="img-fluid w-100"
                  alt="Drink Menu 4"
                  style={{ marginBottom: "10px" }}
                />
              </div>
            </Slider>
          </div>
          <div className="col-md-6 mt-5">
            <div className="row">
              <h1 className="text-white">DRINKS MENU</h1>
              <div className="col-6">
                <img
                  src={drinkmenu24}
                  alt="DRINKS MENU 1"
                  className="img-fluid rounded drinkmenuminiImage"
                />
              </div>
              <div className="col-6">
                <img
                  src={drinkmenu25}
                  alt="DRINKS MENU 2"
                  className="img-fluid rounded drinkmenuminiImage"
                />
              </div>
              <div className="col-6 mt-4">
                <img
                  src={drinkmenu26}
                  alt="DRINKS MENU 3"
                  className="img-fluid rounded drinkmenuminiImage"
                />
              </div>
              <div className="col-6 mt-4">
                <img
                  src={drinkmenu27}
                  alt="DRINKS MENU 4"
                  className="img-fluid rounded drinkmenuminiImage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Reservation />
    </div>
    </>
  );
};

export default FoodDrink;
